// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: 'DM Mono', monospace;
  font-style: normal;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f6f7;
}


.MoreInfo {
  text-align: center;
  font-size: calc(10px + 1vmin) ;
  color: #4a4a4a;
}

/* TODO: change font size for device, too small even with calc */
.footer {
  position: relative;
  margin-top: 100px;
  font-size: calc(12px + 0.1vmin);
  left: 0;
  bottom: 0;
  width: 100%;
  color: #737373;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,iCAAiC;EACjC,kBAAkB;EAClB,yBAAyB;EACzB,mCAAmC;EACnC,kCAAkC;EAClC,yBAAyB;AAC3B;;;AAGA;EACE,kBAAkB;EAClB,8BAA8B;EAC9B,cAAc;AAChB;;AAEA,gEAAgE;AAChE;EACE,kBAAkB;EAClB,iBAAiB;EACjB,+BAA+B;EAC/B,OAAO;EACP,SAAS;EACT,WAAW;EACX,cAAc;EACd,kBAAkB;AACpB","sourcesContent":["body {\n  margin: 0;\n  font-family: 'DM Mono', monospace;\n  font-style: normal;\n  text-transform: uppercase;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #f7f6f7;\n}\n\n\n.MoreInfo {\n  text-align: center;\n  font-size: calc(10px + 1vmin) ;\n  color: #4a4a4a;\n}\n\n/* TODO: change font size for device, too small even with calc */\n.footer {\n  position: relative;\n  margin-top: 100px;\n  font-size: calc(12px + 0.1vmin);\n  left: 0;\n  bottom: 0;\n  width: 100%;\n  color: #737373;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
