// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HeaderTitle {
  padding-top: calc(50px + 5vmin);
  margin: 0% 10% 0% 10%;
  text-align: center;
}

.HeaderTitleText {
  color: #264d1c;
  font-style: normal;
  font-weight: 400;
  padding-bottom: calc(10px + 1vmin);
}

.HeaderTitleText .upper{
  font-size: calc(40px + 1vmin); 
  font-family: 'Gilda Display', serif;
  text-transform: uppercase;
}

.HeaderTitleText .lower{
  font-size: calc(20px + 1vmin); 
  font-family: 'La Belle Aurore', cursive;
  text-transform: capitalize;
}




.HeaderImage{
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
}

.HeaderImage img {
  max-width: calc(50% + 2vmin);
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  /*# orange on inside*/
  /* border: 5px solid #e84b2c; */
  /* outline: 5px solid #264d1c; */
  /*# orange on outside*/
  border: 5px solid #264d1c;
  outline: 5px solid #e84b2c;
  outline-offset: 3px;
}

`, "",{"version":3,"sources":["webpack://./src/HeaderImage.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,gBAAgB;EAChB,kCAAkC;AACpC;;AAEA;EACE,6BAA6B;EAC7B,mCAAmC;EACnC,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,uCAAuC;EACvC,0BAA0B;AAC5B;;;;;AAKA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,4BAA4B;EAC5B,cAAc;EACd,iBAAiB;EACjB,kBAAkB;EAClB,kBAAkB;EAClB,qBAAqB;EACrB,+BAA+B;EAC/B,gCAAgC;EAChC,sBAAsB;EACtB,yBAAyB;EACzB,0BAA0B;EAC1B,mBAAmB;AACrB","sourcesContent":[".HeaderTitle {\n  padding-top: calc(50px + 5vmin);\n  margin: 0% 10% 0% 10%;\n  text-align: center;\n}\n\n.HeaderTitleText {\n  color: #264d1c;\n  font-style: normal;\n  font-weight: 400;\n  padding-bottom: calc(10px + 1vmin);\n}\n\n.HeaderTitleText .upper{\n  font-size: calc(40px + 1vmin); \n  font-family: 'Gilda Display', serif;\n  text-transform: uppercase;\n}\n\n.HeaderTitleText .lower{\n  font-size: calc(20px + 1vmin); \n  font-family: 'La Belle Aurore', cursive;\n  text-transform: capitalize;\n}\n\n\n\n\n.HeaderImage{\n  align-items: center;\n  justify-content: center;\n  max-width: 100%;\n  width: 100%;\n}\n\n.HeaderImage img {\n  max-width: calc(50% + 2vmin);\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n  border-radius: 50%;\n  /*# orange on inside*/\n  /* border: 5px solid #e84b2c; */\n  /* outline: 5px solid #264d1c; */\n  /*# orange on outside*/\n  border: 5px solid #264d1c;\n  outline: 5px solid #e84b2c;\n  outline-offset: 3px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
