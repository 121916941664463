import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import CountdownClock from './CountdownClock';
import HeaderImage from './HeaderImage';
import BurgerMenu from './Menu';
import './fonts.css'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BurgerMenu />
    <HeaderImage />
    <CountdownClock />
    <div className="MoreInfo">
      <br />More info to come!
    </div>
    <div className="footer">
      Content © 2024 Amy and Chris Wedding Day. Website created with love and some tears.
    </div>
  </React.StrictMode>
);