import './CountdownClock.css';
import Countdown, {zeroPad} from 'react-countdown';

function CountdownClock() {
  const wedding_date = new Date("2025-11-18T12:00Z");
  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <span>
        {zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  }
  return (
    <div className="Title">
      <div className='WeddingVenue'>
        Huntsmill Farm, Shalstone
      </div>
      
      <div className='WeddingDate'>
        {wedding_date.toLocaleDateString()}
      </div>

      <div className="CountdownClock">
          <Countdown 
          date={wedding_date}
          renderer={renderer}
          ></Countdown>
      </div>

    </div>
  );
}

export default CountdownClock;
