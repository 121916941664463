import engaged from './static/engaged.jpg'
import './HeaderImage.css';
import './fonts.css'

function HeaderImage() {
    return (
        <div className="HeaderImage">
            <div className="HeaderTitle">
                <div className="HeaderTitleText">
                    <table align="center">
                        <tr class="upper">Amy</tr>
                        <tr class="lower">And</tr>
                        <tr class="upper">Chris</tr>
                    </table>
                </div>
            </div>
            <img alt="engaged" src={engaged} />
        </div>
            );
}

export default HeaderImage;